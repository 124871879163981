<template>
  <div class="sign-in-page sign-page">
    <div class="sign-in-page-wrapper">
      <div class="sign-header">
        <div class="sign-header-left">
          <router-link to="/" class="link-back desc gray small">Back</router-link>
        </div>
        <router-link to="/" class="logo">
          <img src="./../img/logo.svg" class="img"/>
        </router-link>
        <div class="sign-header-right">
          <router-link to="/sign-in" class="desc small link switch-register">
            <b>Sign in</b>
          </router-link>
        </div>
      </div>
      <div class="wrapper">
        <div class="left">
          <div class="left-wrapper">
            <div class="title">Create <br/>account</div>
          </div>
        </div>
        <div class="right">
          <div class="right-wrapper">
            <div class="content">
              <label>
                <div class="desc">Name:</div>
                <input type="text" placeholder="Enter your name" v-model="name"/>
              </label>
              <label>
                <div class="desc">Surname:</div>
                <input type="text" placeholder="Enter your surname" v-model="surname"/>
              </label>
              <label>
                <div class="desc">E-mail:</div>
                <input type="email" placeholder="Enter your e-mail" v-model="email"/>
              </label>
              <label>
                <div class="desc">Phone:</div>
                <input type="number" placeholder="Enter your phone" v-model="phone"/>
              </label>
              <label>
                <div class="desc">Password:</div>
                <input type="password" placeholder="Enter your password" v-model="pass"/>
              </label>
              <label>
                <div class="desc">Confirm password:</div>
                <input type="password" placeholder="Confirm your password" v-model="passConfirm"/>
              </label>
              <div class="chekbox-container">
                <label class="chekbox-label">
                  <div class="chekbox-label-wrapper">
                    <input type="checkbox" name="terms" v-model="terms"/>
                    <div class="checkbox"></div>
                    <span class="desc">I agree with</span>
                    <a @click="$parent.goToPrivacy" class="desc link"> {{ $parent.textPageList[1].title }}</a>
                    <span class="desc"> and</span>
                    <a @click="$parent.goToTerms" class="desc link"> {{ $parent.textPageList[0].title }}</a>
                  </div>
                </label>
              </div>
              <button :class="['button', {'disabled': !requiredRegisterFieldsAreFilled}]" @click="submitRegister">
                <span>Create</span>
              </button>
              <transition name="slide">
                <div v-if="$parent.error" class="error-desc desc red">{{$parent.error}}</div>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SignUp',
  props: [],
  components: {
  },
  data: function() {
    return {
      email: '',
      pass: '',
      name: '',
      surname: '',
      phone: '',
      passConfirm: '',
      terms: false,
    }
  },
  mounted() {
    
  },
  computed: {
    requiredRegisterFieldsAreFilled() {
      let fieldsAreFilled;
      if(
        this.name && this.surname  && this.phone && 
        this.email && this.pass && this.passConfirm
        && this.terms 
      ) {
        fieldsAreFilled = true;
      } else {
        fieldsAreFilled = false;
      }
      return fieldsAreFilled;
    }
  },
  methods: {
    submitRegister() {
      let regData = {
        "name": this.name,
        "surname": this.surname,
        "email": this.email,
        "phone": this.phone,
        "password": this.pass,
        "passConfirm": this.passConfirm
      }
        this.$emit('registration', regData)
    }
  }
}
</script>