<template>
  <main class="main profile-page page-inside">
    <div class="main-wrapper">
      <div class="section hero-section">
      </div>
      <div class="section profile-section">
        <div class="wrapper">
          <div class="profile-form">
            <div class="profile-form-wrapper">
              <div class="wrapper">
                <div class="profile-left" v-if="activeProfilePage !== 'settings'">
                  <div class="balance">
                    <div class="title balance-title medium">My balance</div>
                    <div class="balance-amount">
                      <div class="title medium amount">
                        <b>{{$parent.userBalance}} <span>{{$parent.currency}}</span></b>
                      </div>
                      <button class="top-up button" @click="$parent.openTopUpModal('deposit')">
                        <img src="./../img/plus.svg"/>
                      </button>
                    </div>
                  </div>
                </div>
                <div :class="['profile-right', {'wide': activeProfilePage === 'settings'}]">
                  <div class="content table-content">
                    <div class="tab-list">
                      <div :class="['item title small', {'active': activeTab == item.page}]" v-for="item in profileTabs" :key="item.page" @click="setActiveTab(item.page)">{{item.title}}</div>
                      <button class='button' @click="$parent.logout">
                        Log Out
                      </button>
                    </div>
                    <div class="content-wrapper">
                      <div class="table-container table-container-promtHistory" v-if="activeTab === 'prompts'">
                        <table>
                          <thead>
                            <tr v-if="$parent.promptHistory.length">
                              <td>Preview/Name</td>
                              <td>Prompt</td>
                            </tr>
                            <tr v-else>
                              <td></td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-if="!$parent.promptHistory.length">
                              <td class="bold">You have no prompts</td>
                            </tr>
                            <tr v-for="(item,i) in $parent.promptHistory" :key="i">
                              <td><div class="td-wrapper">{{item.title}}</div></td>
                              <td><div class="td-wrapper" v-html="item.prompt"></div></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="table-container table-container-depositHistory table-container-createdHistory" v-if="activeTab === 'new'">
                        <table>
                          <thead>
                            <tr v-if="$parent.createdHistory.length">
                              <td>№</td>
                              <td>Created</td>
                              <td>Preview/Name</td>
                              <td>Price</td>
                              <td>Status</td>
                              <td>Action</td>
                            </tr>
                            <tr v-else>
                              <td></td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-if="!$parent.createdHistory.length">
                              <td class="bold">You can create <span @click="$parent.goToPromtCreate">Prompt</span></td>
                            </tr>
                            <tr v-for="(item,i) in $parent.createdHistory" :key="i">
                              <td><div class="td-wrapper">{{item.id}}</div></td>
                              <td><div class="td-wrapper">{{$parent.formatDate(item.created_at)}}</div></td>
                              <td>
                                <div class="td-wrapper">
                                  <div class="item">
                                    <div class="img-container">
                                      <img class="img" :src="$parent.imgDomain + item.image_url" />
                                    </div>
                                    <div class="text">{{item.title}}</div>
                                  </div>
                                </div>
                              </td>
                              <td><div class="td-wrapper price">{{item.price}} {{$parent.currency}}</div></td>
                              <td><div class="td-wrapper">
                                <span class="orange" v-if="item.status === 'pending'">Pending</span>
                                <span class="green" v-else-if="item.status === 'approved'">Approved</span>
                                <span v-else>{{item.status}}</span>
                              </div></td>
                              <td>
                                <div class="td-wrapper">
                                  <img v-if="item.status === 'declined' || item.status === 'draft'" @click="editPrompt(item)" class="edit" src="./../img/edit-new.svg"/>
                                  <img v-if="item.status != 'approved'" @click="deleteProduct(item.id)" class="delete" src="./../img/bin.svg"/>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="table-container table-container-depositHistory" v-if="activeTab === 'sell'">
                        <table>
                          <thead>
                            <tr v-if="$parent.depositHistory.length">
                              <td>№</td>
                              <td>Created</td>
                              <td>Transaction</td>
                              <td>Price</td>
                              <td>Status</td>
                              <td>Action</td>
                            </tr>
                            <tr v-else>
                              <td></td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-if="!$parent.depositHistory.length">
                              <td class="bold">You have no transactions</td>
                            </tr>
                            <tr v-for="(item,i) in $parent.depositHistory" :key="i">
                              <td><div class="td-wrapper">{{item.id}}</div></td>
                              <td><div class="td-wrapper">{{$parent.formatDate(item.created_at)}}</div></td>
                              <td>
                                <div class="td-wrapper">
                                  <div class="item">
                                    <div class="img-container" v-if="item.info.image_url">
                                      <img class="img" :src="$parent.imgDomain + item.info.image_url" />
                                    </div>
                                    <div class="text" v-if="item.info.title">{{item.info.title}}</div>
                                    <div class="text" v-else>{{item.type}}</div>
                                  </div>
                                </div>
                              </td>
                              <td><div class="td-wrapper price">{{item.sum}} {{item.currencyCode}}</div></td>
                              <td>
                                <div class="td-wrapper">
                                  <span class="green" v-if="item.status === 'Success'">{{ item.status }}</span>
                                  <span class="red" v-else-if="item.status === 'Error'">{{ item.status }}</span>
                                  <span v-else>{{item.status}}</span>  
                                </div>
                              </td>
                              <td>
                                <button v-if="item.status === 'Success'" @click="downloadInvoice(item.id)" class="button">Download</button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="table-container table-container-orderHistory" v-if="activeTab === 'buy'">
                        <table>
                          <thead>
                            <tr v-if="$parent.orderHistory.length">
                              <td>№</td>
                              <td>Created</td>
                              <td>Q-ty</td>
                              <td>Preview/Name</td>
                              <td>Price</td>
                              <td></td>
                            </tr>
                            <tr v-else>
                              <td></td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-if="!orderHistory.length">
                              <td class="bold">You have no purchased items</td>
                            </tr>
                            <tr v-for="(item,i) in orderHistory" :key="i">
                               <td>
                                <div class="td-wrapper">{{item.id}}</div>
                              </td>
                              <td>
                                <div class="td-wrapper">{{$parent.formatDate(item.created_at)}}</div>
                              </td>
                              <td>
                                <div class="td-wrapper">{{item.items.length}}</div>
                              </td>
                              <td>
                                <div class="td-wrapper">
                                  <div class="item" v-if="item.items.length">
                                    <div class="img-container">
                                      <img class="img" :src="$parent.imgDomain + item.items[0].info.image_url" />
                                    </div>
                                    <div class="text text2">{{item.items[0].info.title}}</div>
                                  </div>
                                </div>
                                <div class="item-list" v-if="orderHistoryVisibleListId === item.id">
                                  <template v-for="(itemPart, i) in item.items">
                                    <div v-if="i !== 0" class="item" :key="i">
                                      <div class="img-container">
                                        <img class="img" :src="$parent.imgDomain + itemPart.info.image_url" />
                                      </div>
                                      <div class="text">{{itemPart.info.title}}</div>
                                      <div class="price">{{item.currencyCode}} {{itemPart.info.price}}</div>
                                    </div>
                                  </template>
                                </div>
                              </td> 
                              <td>
                                <div class="td-wrapper price">
                                  <span v-if="item.items.length">{{item.currencyCode}} {{item.items[0].price}}</span>
                                </div>
                              </td>
                              <td>
                                <div class="td-wrapper">
                                  <img @click="orderHistoryVisibleListId = item.id" class="arrow" v-if="(orderHistoryVisibleListId !== item.id) && item.items.length > 1" src="./../img/arrow-gray.svg"/>
                                  <img @click="orderHistoryVisibleListId = null" class="arrow" v-if="orderHistoryVisibleListId === item.id" src="./../img/arrow-blue.svg"/>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="content" v-if="activeProfilePage === 'withdraw'">
                    <div class="title">Вывод средств</div>
                    <div class="content-wrapper withdraw-content-wrapper">
                      <div class="user-balance-block">
                        <div class="funds item">
                          <div class="title small">Доступные средства</div>
                          <div class="title big">{{$parent.currency}} {{$parent.userBalance}}</div>
                          <button class="button" @click="$parent.openTopUpModal('withdraw')">Вывод</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="content" v-if="activeTab === 'my-profile'">
                    <div class="content-wrapper">
                      <div class="table-container personal-info">
                        <div class="table-container-wrapper">
                          <div class="title medium">Personal information</div>
                          <!-- <div class="input-container">
                            <div class="desc"><span class="red">*</span> First name:</div>
                            <input type="text" placeholder="First name" v-model="name"/>
                          </div> -->
                          <div class="input-container short">
                            <div class="desc">Name:</div>
                            <input type="text" v-model="name" placeholder="Enter your name"/>
                          </div>
                          <div class="input-container short">
                            <div class="desc">Surname:</div>
                            <input type="text" v-model="surname" placeholder="Enter your surname"/>
                          </div>
                          <div class="input-container">
                            <div class="desc">E-mail:</div>
                            <input type="email" placeholder="Enter your e-mail" v-model="email"/>
                          </div>
                          <div class="input-container">
                            <div class="desc">Phone:</div>
                            <input type="text" @input="allowOnlyNumbers" v-model="phone" placeholder="Enter your phone"/>
                          </div>
                          <div class="button-container">
                            <button class="button" @click="updateProfileData">
                              <span>Save changes</span>
                            </button>
                          </div>
                          <transition name="fade">
                            <div class="desc red" v-if="profileUpdateErrorMessaage">{{profileUpdateErrorMessaage}}</div>
                          </transition>
                          <transition name="fade">
                            <div class="desc green" v-if="profileUpdateSuccessMessaage">{{profileUpdateSuccessMessaage}}</div>
                          </transition>
                        </div>
                      </div>
                      <div class="table-container password">
                        <div class="table-container-wrapper">
                          <div class="title medium">Change password</div>
                          <div class="input-container">
                            <div class="desc">Password:</div>
                            <input type="text" placeholder="Enter your password" v-model="curPass"/>
                          </div>
                          <div class="input-container">
                            <div class="desc">New password:</div>
                            <input type="text" placeholder="Enter your new password" v-model="newPass"/>
                          </div>
                          <div class="input-container">
                            <div class="desc">Confirm new password:</div>
                            <input type="text" placeholder="Confirm new password" v-model="repeatPass"/>
                          </div>
                          <button class="button" @click="updatePassword">
                            <span>Save changes</span>
                          </button>
                          <transition name="fade">
                            <div class="desc red" v-if="passUpdateErrorMessaage">{{passUpdateErrorMessaage}}</div>
                          </transition>
                          <transition name="fade">
                            <div class="desc green" v-if="passUpdateSuccessMessaage">{{passUpdateSuccessMessaage}}</div>
                          </transition>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>

export default {
  name: 'Profile',
  props: ['orderHistory', 'currencyValue'],
  components: {
  },
  data: function() {
    return {
      iframeUrl: '',
      email: '',
      name: '',
      surname: '',
      phone: '',
      nickname: '',
      website: '',
      fb_url: '',
      ig_url: '',
      tt_url: '',
      joined: '',
      balance: '',
      profileUpdateSuccessMessaage: '',
      profileUpdateErrorMessaage: '',
      curPass: '',
      newPass: '',
      repeatPass: '',
      passUpdateSuccessMessaage: '',
      passUpdateErrorMessaage: '',
      activeProfilePage: '',
      withdrawName: '',
      withdrawEmail: '',
      orderHistoryVisibleListId: null,
      profileTabs: [
      {
          page: 'my-profile',
          title: 'Personal information'
        },
        {
          page: 'prompts',
          title: 'Purchased Prompts'
        },
        {
          page: 'buy',
          title: 'Order History'
        },
        {
          page: 'sell',
          title: 'Transactions'
        },
        /*{
          page: 'new',
          title: 'Selling Prompts'
        }*/
      ],
      activeTab: 'my-profile'
    }
  },
  mounted() {
    this.activeTab = this.$route.params.page;
    this.getProfileData();
  },
  methods: {
    allowOnlyNumbers(){
      this.phone = this.phone.replace(/[^0-9.,]/g, '');

      // Split the input into integer and decimal parts
      const parts = this.phone.split(/[.,]/);
      const integerPart = parts[0];
      let decimalPart = parts[1];

      // Limit decimal part to two digits
      if (decimalPart !== undefined && decimalPart.length > 2) {
        decimalPart = decimalPart.slice(0, 2);
      }

      // Concatenate the integer and decimal parts back
      this.phone = decimalPart !== undefined ? `${integerPart}.${decimalPart}` : integerPart;
    },
    deleteProduct(id) {
      this.$http.post(process.env.VUE_APP_API + 'user/prompts/' + id + '/delete')
      .then((res) => {
        if (res.data.status == "OK") {
          this.$parent.getCreatedHistory();
        } else {
          this.$emit('setNewError', res.data.message)
        }
      })
      .catch((res) => {
        this.$emit('setNewError', res.response.data.message)
      })
    },
    updateProfileData() {
      let profileData = {
        "email": this.email,
        "name": this.name,
        "phone": this.phone,
        "surname": this.surname
      }
      this.$http.put(process.env.VUE_APP_API + 'user/profile', profileData)
      .then((res) => {
        if (res.data.status == "OK") {
          this.profileUpdateSuccessMessaage = 'Success';
          setTimeout(() => {
            this.profileUpdateSuccessMessaage = '';
          }, 3000);
        }
      })
      .catch((res) => {
        if (res.response.data.errors) {
            let firstKey = Object.keys(res.response.data.errors)[0];
            if (typeof res.response.data.errors[firstKey] !== 'undefined') {
                this.profileUpdateErrorMessaage = res.response.data.errors[firstKey][0]
            } else {
                this.profileUpdateErrorMessaage = res.response.data.errors[0]
            }
        } else if (res.response.data.message) {
          this.profileUpdateErrorMessaage = res.response.data.message
        }

        if(res.response.status === 401) {
          this.$parent.openSignInModal();
        }

        setTimeout(() => {
          this.profileUpdateErrorMessaage = '';
        }, 3000);
      })
    },
    updatePassword() {
      let passwordList = {
        "cur_password": this.curPass,
        "new_password": this.newPass,
        "repeat_password": this.repeatPass
      }
      this.$http.post(process.env.VUE_APP_API + 'user/change-password', passwordList)
      .then((res) => {
        if (res.data.status == "OK") {
          this.passUpdateSuccessMessaage = 'Success';
          this.curPass = '',
          this.newPass = '',
          this.repeatPass = '';
          setTimeout(() => {
            this.passUpdateSuccessMessaage = '';
          }, 3000);
        }
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignInModal();
        }
        this.passUpdateErrorMessaage = res.response.data.message;
        setTimeout(() => {
          this.passUpdateErrorMessaage = '';
        }, 3000);
      })
    },
    goToProfilePage(page) {
      this.$router.push({path: '/profile/' + page});
      this.activeProfilePage = page;
    },
    setActiveTab(page) {
      this.activeTab = page;
    },
    
    getProfileData() {
      this.$http.get(process.env.VUE_APP_API + 'user/profile')
      .then((res) => {
          this.email = res.data.email;
          this.name = res.data.name;
          this.surname = res.data.surname;
          this.phone = res.data.phone;
          this.joined = res.data.created_at;
          this.balance = res.data.balance;
          this.nickname = res.data.nickname;
          this.website = res.data.web_url;
          this.fb_url = res.data.fb_url;
          this.ig_url = res.data.ig_url;
          this.tt_url = res.data.tt_url;
          
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignInModal();
        }
      })
    },
    editPrompt(item) {
      this.$emit('editPrompt', item);
    },
    downloadInvoice(id) {
      this.$http.get(process.env.VUE_APP_API + 'user/invoice/download?id=' + id)
      .then((res) => {
        window.location = res.config.url;
      })
      .catch((res) => {
        this.$emit('setNewError', res.response.data.message)
      })
    },
  }
}
</script>