<template>
  <main class="main page-inside promt-create-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper">
          <div class="title big">Sell</div>
          <!-- <div class="type" v-if="step !== 1">
            <img :src="$parent.imgDomain + category.image_url" class="img"/>
            <div class="title small">{{category.title}}</div>
          </div> -->
        </div>
      </div>
      <div class="section create-section">
        <div class="wrapper">
          <div class="title">Select tipe of AI you want to sell</div>
          <div v-if="step === 1" class="type-list">
            <label v-for="item in $parent.modelOptions" :key="item.id">
              <input type="radio" v-model="category" :value="item"/>
              <div class="label-wrapper">
                <img :src="$parent.imgDomain + item.image_url" class="img"/>
                <div class="title small">{{item.title}}</div>
              </div>
            </label>
          </div>
          <div class="form-container" v-if="step === 2">
            <div class="form">
              <div class="form-wrapper">
                <div class="select-container" v-if="activeCategories">
                  <div class="title small"><span class="red">*</span> Category:</div>
                  <select v-model="activeCategory">
                    <option :value="item.id" v-for="item in activeCategories " :key="item.id">{{item.name}}</option>
                  </select>
                </div>
                <div class="input-container">
                  <div class="title small"><span class="red">*</span> Name:</div>
                  <input type="text" v-model="title" placeholder="Enter name for the future prompt"/>
                </div>
                <div class="input-container">
                  <div class="title small"><span class="red">*</span> Description:</div>
                  <textarea type="text" v-model="desc" placeholder="Enter full description"></textarea>
                </div>
                <div class="input-container input-container-price">
                  <div class="title small"><span class="red">*</span> Price:</div>
                  <input type="text"  @input="allowOnlyNumbers" v-model="price" placeholder="0.00"/>
                  <div class="title small">{{$parent.currency}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-container" v-if="step === 3">
            <div class="form">
              <div class="form-wrapper">
                <div class="input-container">
                  <div class="title small"><span class="red">*</span> Prompt:</div>
                  <textarea type="text" v-model="prompt" placeholder="Enter name for the future prompt"></textarea>
                </div>
                <div class="input-container">
                  <div class="title small"><span class="red">*</span> Instruction:</div>
                  <textarea type="text" v-model="instructions" placeholder="Enter full description"></textarea>
                </div>
                <div class="input-container">
                  <div class="title small add-image">Add your images. Each image should have a consistent style, but different subjects</div>
                  <label class="img-label">
                    <input type="file" @change="setImage" />
                    <div :class="['prompt-desc', {'active': image}]">
                      <img v-if="image"  :src='image' class="img"/>
                      <img v-if="!image" src="./../img/plus-gray.svg" class="plus"/>
                    </div>
                  </label>
                  <label class="img-label" v-if="image">
                    <input type="file" @change="setImage2" />
                    <div :class="['prompt-desc', {'active': image2}]">
                      <img v-if="image2"  :src='image2' class="img"/>
                      <img v-if="!image2" src="./../img/plus-gray.svg" class="plus"/>
                    </div>
                  </label>
                  <label class="img-label" v-if="image2">
                    <input type="file" @change="setImage3" />
                    <div :class="['prompt-desc', {'active': image3}]">
                      <img v-if="image3"  :src='image3' class="img"/>
                      <img v-if="!image3" src="./../img/plus-gray.svg" class="plus"/>
                    </div>
                  </label>
                  <label class="img-label" v-if="image3">
                    <input type="file" @change="setImage4" />
                    <div :class="['prompt-desc', {'active': image4}]">
                      <img v-if="image4"  :src='image4' class="img"/>
                      <img v-if="!image4" src="./../img/plus-gray.svg" class="plus"/>
                    </div>
                  </label>
                </div>
                <div class="title title-price">
                  Set price: {{ price }} {{$parent.currency}}
                </div>
              </div>
            </div>
          </div>
          <transition name="fade">
            <div class="error-desc desc red" v-if="$parent.error">{{ $parent.error }}</div>
          </transition>
          <transition name="fade">
            <div class="title title-success" v-if="step === 'success'">Your prompt has been successfully sent for review</div>
          </transition>
          <div class="path">
            <div :class="['step prev', {'disabled': step === 1}]" @click="setStep(step - 1)">
              <div class="title small">Back</div>
            </div>
            <div :class="['step next', {'disabled': step === 4}]" @click="setStep(step + 1)">
              <div class="title small">Next</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>

export default {
  name: 'PromtCreate',
  props: [],
  components: {
  },
  data: function() {
    return {
      category: '',
      activeCategory: '',
      step: 1,
      title: '',
      desc: '',
      price: '',
      prompt: '',
      instructions: '',
      image: null,
      imageFile: null,
      image2: null,
      imageFile2: null,
      image3: null,
      imageFile3: null,
      image4: null,
      imageFile4: null,
      editData: null
    }
  },
  mounted() {
    let self = this;
    if (this.$parent.editData) {
      this.editData = this.$parent.editData;
    } else if (this.$route.params.id) {
      this.$http.get(process.env.VUE_APP_API + 'user/prompts/created')
      .then((res) => {
        let createdHistory = res.data.payload;
        let obj = createdHistory.find(item => item.id == this.$route.params.id);
        this.editData = obj;
        const chosenCategory = this.$parent.modelOptions.find(obj => obj.id === this.editData.category_id);
        this.category = chosenCategory;
        this.title = this.editData.title;
        this.desc = this.editData.description;
        this.price = this.editData.price;
        this.prompt = this.editData.prompt;
        this.instructions = this.editData.instructions;
        this.activeCategory = this.editData.activeCategory;
        this.image = this.$parent.imgDomain + this.editData.image_url;
        this.image2 = this.$parent.imgDomain + this.editData.image_url2;
        this.image3 = this.$parent.imgDomain + this.editData.image_url3;
        this.image4 = this.$parent.imgDomain + this.editData.image_url4;
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignInModal();
        }
      })
    }
    setTimeout(function() {
      self.$parent.clearEditData();
    }, 200)
    if (this.editData) {
      const chosenCategory = this.$parent.modelOptions.find(obj => obj.id === this.editData.category_id);
      this.category = chosenCategory;
      this.title = this.editData.title;
      this.desc = this.editData.description;
      this.price = this.editData.price;
      this.prompt = this.editData.prompt;
      this.instructions = this.editData.instructions;
      this.activeCategory = this.editData.activeCategory;
      this.image = this.$parent.imgDomain + this.editData.image_url;
      this.image2 = this.$parent.imgDomain + this.editData.image_url2;
      this.image3 = this.$parent.imgDomain + this.editData.image_url3;
      this.image4 = this.$parent.imgDomain + this.editData.image_url4;
    
    } else {
      this.editData = null;
    }
  },
	computed: {
		activeCategories: function() {
      console.log('category: ' + this.category)
      console.log(this.$parent.categoryOptions)
      console.log(this.category)
      let activeCategories = this.$parent.categoryCreateOptions.filter(item => item.parent_id == this.category.id);
      return activeCategories
    },
	},
  
  methods: {
    allowOnlyNumbers(){
      this.price = this.price.replace(/[^0-9.,]/g, '');

      // Split the input into integer and decimal parts
      const parts = this.price.split(/[.,]/);
      const integerPart = parts[0];
      let decimalPart = parts[1];

      // Limit decimal part to two digits
      if (decimalPart !== undefined && decimalPart.length > 2) {
        decimalPart = decimalPart.slice(0, 2);
      }

      // Concatenate the integer and decimal parts back
      this.price = decimalPart !== undefined ? `${integerPart}.${decimalPart}` : integerPart;
    },
    setImage(event) {
      const selectedFile = event.target.files[0];
      this.imageFile = selectedFile;
      if (selectedFile) {
        const reader = new FileReader();

        reader.onload = () => {
          this.image = reader.result;
        };

        reader.readAsDataURL(selectedFile);
      } else {
        this.image = null;
      }
    },
    setImage2(event) {
      const selectedFile = event.target.files[0];
      this.imageFile2 = selectedFile;
      if (selectedFile) {
        const reader = new FileReader();

        reader.onload = () => {
          this.image2 = reader.result;
        };

        reader.readAsDataURL(selectedFile);
      } else {
        this.image2 = null;
      }
    },
    setImage3(event) {
      const selectedFile = event.target.files[0];
      this.imageFile3 = selectedFile;
      if (selectedFile) {
        const reader = new FileReader();

        reader.onload = () => {
          this.image3 = reader.result;
        };

        reader.readAsDataURL(selectedFile);
      } else {
        this.image3 = null;
      }
    },
    setImage4(event) {
      const selectedFile = event.target.files[0];
      this.imageFile4 = selectedFile;
      if (selectedFile) {
        const reader = new FileReader();

        reader.onload = () => {
          this.image4 = reader.result;
        };

        reader.readAsDataURL(selectedFile);
      } else {
        this.image4 = null;
      }
    },
    setStep(step) {
      if (step === 0) {
        this.step = 1;
      }
      if (step === 4) {
        this.step = 3;
      }

      if (this.step === 1 && step === 2) {
        if (this.category) {
          this.step = step;
        }
      }
      if (this.step === 2 && step === 3) {
        if (this.title && this.desc && this.price && this.activeCategory) {
          this.step = step;
        }
      }
      if (this.step === 3 && step === 4) {
        if (this.prompt && this.instructions) {
          let param;
          if (this.imageFile) {
            param = {
              'image': this.imageFile,
              'image2': this.imageFile2,
              'image3': this.imageFile3,
              'image4': this.imageFile4,
              'parent_cat_id': this.category.id,
              'price': this.price,
              'title': this.title,
              'description': this.desc,
              'prompt': this.prompt,
              'instructions': this.instructions,
              'category_id': this.activeCategory
            }
          } else {
            param = {
              'parent_cat_id': this.category.id,
              'price': this.price,
              'title': this.title,
              'description': this.desc,
              'prompt': this.prompt,
              'instructions': this.instructions,
              'category_id': this.activeCategory
            }
          }
          console.log(this.editData)
          if (this.editData) {

            console.log(1)
            this.$http.post(process.env.VUE_APP_API + 'user/prompts/' + this.editData.id + '/edit', param, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }).then((res) => {
              if (res.data.status == "OK") {
                this.step = 'success';
                let self = this;
                setTimeout(function() {
                  self.step = 1;
                  self.category = '',
                  self.title = '',
                  self.desc  ='',
                  self.price = '',
                  self.prompt = '',
                  self.instructions = '',
                  self.activeCategory = '',
                  self.image = null,
                  self.imageFile = null;
                  self.image2 = null,
                  self.imageFile2 = null;
                  self.image3 = null,
                  self.imageFile3 = null;
                  self.image4 = null,
                  self.imageFile4 = null;
                }, 2000);
              }
            })
            .catch((res) => {
              this.$emit('setError', res.response.data.message)
              if(res.response.status === 401) {
                this.$parent.openSignInModal();
              }
            })
          } else {

            console.log(2)
            this.$http.post(process.env.VUE_APP_API + 'user/prompts/create', param, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            })
            .then((res) => {
              if (res.data.status == "OK") {
                let par = {'prompt_id' : res.data.id}
                this.$http.post(process.env.VUE_APP_API + 'user/prompts/send-to-approve', par, {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  }
                })
                .then(() => {
                  if (res.data.status == "OK") {
                    this.step = 'success';
                    let self = this;
                    setTimeout(function() {
                      self.step = 1;
                      self.category = '',
                      self.title = '',
                      self.desc  ='',
                      self.price = '',
                      self.prompt = '',
                      self.instructions = '',
                      self.activeCategory = '',
                      self.image = null,
                      self.imageFile = null;
                      self.image2 = null,
                      self.imageFile2 = null;
                      self.image3 = null,
                      self.imageFile3 = null;
                      self.image4 = null,
                      self.imageFile4 = null;
                    }, 2000);
                  }
                })
                .catch((res) => {
                  this.$emit('setError', res.response.data.message)
                  console.log(res);
                })
              }
            })
            .catch((res) => {
              this.$emit('setError', res.response.data.message)
              if(res.response.status === 401) {
                this.$parent.openSignInModal();
              }
            })
          }
        }
      }
      if (step < this.step) {
        this.step = step;
      }
    }
  }
}
</script>