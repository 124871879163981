<template>
  <div class="sign-in-page sign-page">
    <div class="sign-in-page-wrapper">
      <div class="sign-header">
        <div class="sign-header-left">
          <router-link to="/" class="link-back desc gray small">Back</router-link>
        </div>
        <router-link to="/" class="logo">
          <img src="./../img/logo.svg" class="img"/>
        </router-link>
        <div class="sign-header-right">
          <router-link to="/sign-up" class="desc small link switch-register">
            <b>Create your account now!</b>
          </router-link>
        </div>
      </div>
      <div class="wrapper">
        <div class="left">
          <div class="left-wrapper">
            <div class="title" v-if="loginContentVisible">Sign in</div>
            <div class="title" v-if="!loginContentVisible">Forgot password?</div>
            <a class="desc small link" v-if="loginContentVisible" @click="switchToforgotPass">
              <b>Forgot password?</b>
            </a>
            <a class="desc small link" v-if="!loginContentVisible" @click="switchToLogin">
              <b>Sign in</b>
            </a>
          </div>
        </div>
        <div class="right">
          <div class="right-wrapper">
            <div class="content" v-if="loginContentVisible">
              <label>
                <div class="desc">E-mail:</div>
                <input type="email" placeholder="Enter your e-mail" v-model="email"/>
              </label>
              <label>
                <div class="desc">Password:</div>
                <input type="password" placeholder="Enter your password" v-model="pass"/>
              </label>
              <button class="button" @click="submitLogin">
                <span>Log In</span>
              </button>
              <transition name="slide">
                <div v-if="$parent.error" class="error-desc desc red">{{$parent.error}}</div>
              </transition>
            </div>
            <div class="content" v-if="forgotPassContentVisible">
              <label>
                <div class="desc">E-mail:</div>
                <input type="email" placeholder="Enter your e-mail" v-model="email"/>
              </label>
              <button class="button" @click="submitForgotPass">
                <span>Recover</span>
              </button>
              <transition name="fade">
                <div v-if="$parent.successMessage" class="desc green">{{$parent.successMessage}}</div>
              </transition>
              <transition name="slide">
                <div v-if="$parent.error" class="error-desc desc red">{{$parent.error}}</div>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SignIn',
  props: [],
  components: {
  },
  data: function() {
    return {
      loginContentVisible: true,
      forgotPassContentVisible: false,
      email: '',
      pass: ''
    }
  },
  mounted() {
    
  },
  methods: {
    switchToforgotPass() {
      this.$parent.clearError();
      setTimeout(()=> {
        this.loginContentVisible = false;
        this.forgotPassContentVisible = true;
      }, 100)
    },
    switchToLogin() {
      this.$parent.clearError();
      setTimeout(()=> {
        this.forgotPassContentVisible = false;
        this.loginContentVisible = true;
      }, 100)
    },
    submitLogin() {
      let loginData = {
        "username": this.email,
        "password": this.pass
      }
      this.$emit('login', loginData)
    },
    submitForgotPass() {
      let data = {
        "email": this.email
      }
      this.$emit('forgotPass', data)
    },
  }
}
</script>