<template>
	<header class="header">
    <div class="wrapper">
      <div class="left">
        <router-link to="/" class="logo">
          <img src="./../img/logo.svg" class="img"/>
        </router-link>
      </div>
      <div class="nav">
        <div class="nav__item">
          <div @click="scrollToSection('home')" class="desc small hover-link">Home</div>
        </div>
        <div class="nav__item">
          <div @click="scrollToSection('about')" class="desc small hover-link">About Us</div>
        </div>
        <div class="nav__item">
          <div @click="scrollToSection('howToUse')" class="desc small hover-link">How to use</div>
        </div>
        <div class="nav__item">
          <div @click="$parent.openContactModal" class="desc small">Contact</div>
        </div>
        <div class="nav__item">
          <router-link to="/product-list" class="desc small">Shop</router-link>
        </div>
      </div>
      <div class="right">
        <div class="select-container" v-if="curr && ($parent.currencyOptions.length > 1)">
          <Select2 class="select2 single currency-select" v-model="curr"
            @select="selectCurr($event)"
            :placeholder="curr"
            :options="currOptions"
          />
        </div>
        <div class="header-profile" v-if="$parent.isAuth">
          <div class="cart" @click="$parent.openBuyModal('')">
            <img class="img" src="./../img/cart.svg"/>
            <div v-if="$parent.cartContents && $parent.cartContents.length" class="indicator">{{ $parent.cartContents.length }}</div>
          </div>
          <router-link to="/profile/my-profile" class="avatar-container button">
            <img class="img" src="./../img/user.svg"/>
          </router-link>
        </div>
        <a v-else class="desc small header-button modal-open" @click="$emit('openSignInModal')">
          <span>Sign In</span>
          <img class="img" src="./../img/enter.svg"/>
        </a>
      </div>
    </div>
  </header>
</template>

<script>

import Select2 from 'vue3-select2-component';
export default {
	name: 'Header',
  props: ['userBalance', 'avatar_path', 'currencyCode', 'currencyValue'],
  components: {
		Select2
	},
	data: function() {
		return {
      curr: ''
		}
	},
  computed: {
    currOptions() {
      return this.$parent.currencyOptions.map(({code}) => code);
    }
  },
  watch: {
    currencyCode: function (newValue) {
      this.curr = newValue
    }
  },

  mounted() {
    this.curr = this.currencyCode
  },
	methods: {
    scrollToSection(section) {
      this.$emit('scrollToSection', section)
    },
    openSignInModal() {
      this.$emit('openSignInModal')
    },
    setStableDiffusionModel() {
      let model = this.$parent.models.find((obj) => obj.id === 1)
      this.$emit('setActiveModel', model);
    },
    selectCurr(event) {
      this.curr = event.text;
      this.changeCurrency(event.text)
      localStorage.setItem("currency", event.text);
    },
    changeCurrency(item) {
      this.$emit('changeCurrency', item)
    },
	}
}
</script>
