<template>
	<footer class="footer">
    <div class="footer-section">
      <div class="wrapper">
        <div class="left">
          <router-link to="/" class="logo">
            <img src="./../img/logo.svg" class="img"/>
          </router-link>
          <div class="desc small gray">
            *In order to use prompts, you must register on the appropriate platforms
          </div>
          <ul class="list">
            <li class="item">
              <img src="./../img/methods/3ds.svg" class="img"/>
            </li>
            <li class="item">
              <img src="./../img/methods/visa.svg" class="img"/>
            </li>
            <li class="item">
              <img src="./../img/methods/mc.svg" class="img"/>
            </li>
          </ul>
          <ul class="socials" v-if="$parent.socialLinks && $parent.socialLinks.length">
            <li v-for="link in $parent.socialLinks" :key="link.image" class="nav__item nav__item-text-pages">
              <a :href="link.url" class="desc" target="_blank">
                <img :src="link.image" :alt="link.url" class="img" />
              </a>
            </li>
          </ul>
        </div>
        <div class="nav-list">
          <div class="nav">
            <div class="nav__item">
              <div @click="scrollToSection('home')" class="desc hover-link">Home</div>
            </div>
            <div class="nav__item">
              <div @click="scrollToSection('about')" class="desc hover-link">About Us</div>
            </div>
            <div class="nav__item">
              <div @click="scrollToSection('howToUse')" class="desc hover-link">How to use</div>
            </div>
            <div class="nav__item">
              <div @click="$parent.openContactModal" class="desc">Contact</div>
            </div>
            <div class="nav__item">
              <router-link to="/product-list" class="desc small">Shop</router-link>
            </div>
          </div>
        </div>

        <div class="nav-list">
          <ul class="nav">
            <li class="nav__item" @click="goToFaqPage">
              <div class="desc">Faq</div>
            </li>
            <template v-for="item in $parent.textPageList">
              <li class="nav__item nav__item-text-pages" :key="item.id">
                <div @click="$parent.goToPage(item.id)" class="desc">
                  {{item.title}}
                </div>
              </li>
            </template>
          </ul>
        </div>
        <div class="right">
          <div class="nav">
              <div class="nav__item">
                <div class="desc" v-if="$parent.settings.company_name">{{ $parent.settings.company_name }}</div>
              </div>
              <div class="nav__item">
                <div class="desc small">
                    <span v-if="$parent.settings.company_address">{{ $parent.settings.company_address }}</span>
                  </div>
              </div>
              <div class="nav__item">
                <div class="desc small">
                  <span v-if="$parent.settings.company_reg_number">Company number: {{ $parent.settings.company_reg_number }}</span>
                </div>
              </div>
              <div class="nav__item">
                  <div class="desc small">
                    <a :href="'mailto:' + $parent.settings.support_email" v-if="$parent.settings.support_email">
                      <b>{{ $parent.settings.support_email }}</b>
                    </a>
                  </div>
              </div>
          </div>
          <div class="copyright desc small gray">{{ $parent.settings.copyright }}</div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>

export default {
	name: 'Footer',
	props: [],
	data: function() {
		return {
		}
	},
	methods: {
    scrollToSection(section) {
      this.$emit('scrollToSection', section)
    },
		goToFaqPage() {
      this.$router.push({ path: '/faq' })
    },
    setStableDiffusionModel() {
      let model = this.$parent.models.find((obj) => obj.id === 1)
      this.$emit('setActiveModel', model);
    }
	},
	mounted() {
    
  }
}
</script>
