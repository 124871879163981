<template>
  <main class="main product-page page-inside">
    <div class="main-wrapper">
      <section class="section hero-section">
        <div class="wrapper">
        </div>
      </section>
      <section class="section preview-section">
        <div class="wrapper">
          <transition name="fade">
            <div class="product-preview" v-if="productPageItem">
              <div class="preview-container">
                <div class="preview-wrapper">
                  <div class="preview">
                    <img :src="$parent.imgDomain + productPageItem.image_url" class="img"/>
                  </div>
                </div>
              </div>
              <div class="text">
                <div class="text-wrapper">
                  <div class="left">
                    <div class="preview-tag desc" v-if="tag">
                      <b>{{tag}}</b>
                    </div>
                    <div class="title regular">{{productPageItem.title}}</div>
                    <div class="spec-text">
                      <div class="list">
                        <div class="item" v-if="productPageItem.created_at">
                          <div class="title">
                            Created:
                          </div>
                          <div class="desc small">
                            {{ $parent.formatDate(productPageItem.created_at) }}
                          </div>
                        </div>
                        <div class="item" v-if="category.length">
                          <div class="title">
                            In categories:
                          </div>
                          <div class="desc small gray">
                            {{category}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="right">
                    <div class="price-container">
                      <div class="price-title title">{{productPageItem.price}}</div>
                      <div class="price-value title">
                        <span class="currency">{{currency}}</span>
                      </div>
                    </div>
                    <button class="button" @click="openBuyModal(productPageItem)">Get prompt</button>
                  </div>
                </div>
              </div>
              <div class="description-container">
                <div class="description">
                  <div class="desc small">
                    <b>Description:</b>
                  </div>
                  <div class="desc small" v-html="productPageItem.description"></div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </section>
    </div>
  </main>
</template>
<script>
export default {
  name: 'ProductPage',
  props: ['currency', 'productPageItem', 'currencyValue'],
  components: {
  },
  data: function() {
    return {
      tag: '',
      category: [],
      models: [],
      categories: []
    }
  },
	watch: {
    productPageItem: function(newVal) {
      this.$http.get(process.env.VUE_APP_API + 'categories')
      .then((res) => {
        this.models = res.data.payload.filter(item => item.parent_id === null);
        let newTag = this.models.find(item => item.id === newVal.parent_cat_id);
        newTag = newTag['title']
        this.tag = newTag;

        this.categories = res.data.payload.filter(item => item.parent_id !== null);
        let category = this.categories.find(item => item.id === newVal.category_id);
        category = category['title']
        this.category = category;
        
      })
      .catch(() => {
          
      })
    },
    currency: function(newVal) {
      console.log(newVal)
      const id = this.$route.params.id.replace(/\D/g, '');
      const type = this.$route.params.id.replace(/[^A-Za-z]+/g, ' ');
      this.$emit('getProductPageContent', id, type);
    }
	},
  created() {
    
  },
  mounted() {
    const id = this.$route.params.id.replace(/\D/g, '');
    const type = this.$route.params.id.replace(/[^A-Za-z]+/g, ' ');
    this.$emit('getProductPageContent', id, type);
  },
  methods: {
		openBuyModal(item) {
			this.$emit('openBuyModal', item, item.type)
		}
  }
}
</script>