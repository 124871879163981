<template>
  <div class="modal buy-modal">
    <div class="overlay"  @click="$parent.closeBuyModal"></div>
    <div class="wrapper">
      <img class="close" @click="$parent.closeBuyModal" src="./../img/close.svg"/>
      <div class="container">
        <div>
          <div class="form-wrapper">
            <div class="form-fields">
              <div class="title">Cart</div>
              <table>
                <tbody v-if="cartContents.length">
                  <tr v-for="item in cartContents" :key="item.id">
                    <td>
                      <div class="flex">
                        <img @click="removeFromCart(item)" src="./../img/bin.svg" class="bin"/>
                        <div class="preview">
                          <img :src="$parent.imgDomain + item.item.img_url" class="img"/>
                        </div>
                        <div class="desc">{{item.item.title}}</div>
                      </div>
                    </td>
                    <td>
                      {{item.item.price}} {{$parent.currency}}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="bottom">
                <div class="total-container desc">
                  <div class="total-desc">Total:</div> {{totalPrice}} {{$parent.currency}}
                </div>
                <button class="button" v-if="cartContents && cartContents.length" @click="buyProduct">Buy</button>
              </div>
              <transition name="slide">
                <div v-if="$parent.error" class="error-desc desc red">{{$parent.error}}</div>
              </transition>
              <div class="desc gray">After checkout this prompt will appear in your account page.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BuyModal',
  props: ['cartContents', 'totalPrice', 'currencyValue'],
  components: {
  },
  data: function() {
    return {
      
    }
  },
  mounted() {
    
  },
  computed: {
    
  },
  methods: {
    buyProduct() {
      this.$parent.buyProduct();
    },
    removeFromCart(item) {
      this.$emit('removeFromCart', item)
    }
  }
}
</script>