<template>
  <main class="main home-page">
    <div class="main-wrapper">
      <div class="section hero-section" id="home">
        <div class="wrapper">
          <div class="hero-text">
            <img src="./../img/logo.svg" class="img"/>
            <div class="title">Your AI Prompt Marketplace</div>
            <div class="desc gray">
              High-quality prompts, expert-reviewed, and customizable to your needs.
            </div>
            <div class="note">
              <img src="./../img/wlpprs.png" class="wlpprs"/>
              <div class="desc">
                Unlock the full potential of artificial intelligence with the <b>perfect prompt.</b>  
              </div>
              <div class="pen-container">
                <img src="./../img/pen.png" class="pen"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section about-section" id="about">
        <div class="wrapper">
          <div class="img-container">
            <img src="./../img/piece.svg" class="piece"/>
            <img src="./../img/text.png" class="text-img"/>
          </div>
          <div class="text">
            <div class="title regular">About Us</div>
            <div class="desc gray">
              promptexcel.com connects human creativity with the power of artificial intelligence. We offer a platform where you can buy prompts that help you get the most out of your AI models.
            </div>
            <router-link to="/product-list" class="button">Discover More</router-link>
          </div>
        </div>
      </div>

      <div class="section how-to-use-section" id="howToUse">
        <div class="wrapper">
          <div class="title title-main">How It Works?</div>
          <div class="scheme">
            <div class="scheme-part scheme-part1">
              <div class="card">
                <div class="title small">
                  <b>Explore:</b>
                </div>
                <div class="desc small">
                  Browse through our rich collection of expert-reviewed prompts.
                </div>
                <img src="./../img/glass.png" class="glass"/>
              </div>
            </div>
            <div class="scheme-part scheme-part2">
              <div class="card">
                <div class="title small">
                  <b>Select:</b>
                </div>
                <div class="desc small">
                  Find the prompt that fits your needs perfectly.
                </div>
              </div>
              <div class="card">
                <div class="title small">
                  <b>Purchase:</b>
                </div>
                <div class="desc small">
                  Make a secure purchase.
                </div>
              </div>
            </div>
            <div class="scheme-part scheme-part3">
              <div class="card">
                <div class="title small">
                  <b>Apply:</b>
                </div>
                <div class="desc small">
                  Implement your new prompt into your AI model and watch it work with renewed strength!
                </div>
                <div class="img-container">
                  <img src="./../img/wlpprs2.png" class="wlpprs"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section shop-section">
        <div class="wrapper">
          <div class="left">
            <div class="title">Shop</div>
            <div class="desc small gray">
              Ready to turn your AI into a genius assistant?
            </div>
            <router-link to="/product-list" class="button">Get Started Now</router-link>
          </div>
          <div class="right">
            <div class="list">
              <div class="desc">
                Browse through a curated selection of high-quality prompts.
              </div>
              <div class="desc">
                Instant use after purchase.
              </div>
              <div class="desc">
                24/7 customer support.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section contact-section">
        <div class="wrapper">
          <div class="title">
            Contact Us
          </div>
          <div class="desc small gray">
            If you have any questions, don’t hesitate to reach out.
          </div>
          <button @click="$parent.openContactModal" class="button">Contact Us</button>
        </div>
      </div>

    </div>
  </main>
</template>
<script>


export default {
  name: 'Home',
  props: ['currency', 'modelOptions', 'currencyOptions', 'currencyValue', 'models'],
  components: {
  },
  data: function() {
    return {
      imgDomain: ''
    }
  },
  watch: {
    modelOptions: function(newVal) {
      this.$emit('setActiveSlider', newVal[0].id)
      this.$emit('getActiveModelProducts', newVal[0].id);
    },
    currencyOptions: function(newVal) {
      this.currencyOptions = newVal;
    },
    
  },
  mounted() {
    this.imgDomain = this.$parent.imgDomain;
    if (this.modelOptions.length) {
      this.$emit('setActiveSlider', this.modelOptions[0].id)
      this.$emit('getActiveModelProducts', this.modelOptions[0].id);
    }
  },
  methods: {
    chooseCategory(item) {
      this.$emit('chooseCategory', item)
    },
    goToProduct(item) {
      this.$emit('goToProduct', item)
    },
    setStableDiffusionModel() {
      let model = this.models.find((obj) => obj.id === 135)
      this.$emit('setActiveModel', model);
    },
    setMidjourneyModel() {
      let model = this.models.find((obj) => obj.id === 136)
      this.$emit('setActiveModel', model);
    },
    setChatGptModel() {
      let model = this.models.find((obj) => obj.id === 134)
      this.$emit('setActiveModel', model);
    },
    setDalle2Model() {
      let model = this.models.find((obj) => obj.id === 137)
      this.$emit('setActiveModel', model);
    },
    setActiveSlider(id) {
      this.$emit('getActiveModelProducts', id)
    },
    openBuyModal(item, type) {
      this.$emit('openBuyModal', item, type)
    },
  }
}
</script>