<template>
	<div class="item-wrapper" v-if="item">
		
		<div class="top">
			<div v-if="!withUser" class="preview-container">
				<div class="product-overlay" @click="goToSliderItem(item)">
					<img src="./../img/magnifying-glas-white.svg" class="img"/>
					<div class="title small">View</div>
				</div>
				<div class="preview" @click="goToSliderItem(item)" v-if="lazy">
					<img :data-lazy="imgDomain + item.image_url" class="img"/>
				</div>
				<div class="preview" @click="goToSliderItem(item)" v-else>
					<img :src="imgDomain + item.image_url" class="img"/>
				</div>
			</div>
			<!-- <div v-if="!withUser" class="desc" @click="goToSliderItem(item)" v-html="item.description"></div> -->
		</div>
		<div class="bottom">
			<div v-if="!withUser" class="title" @click="goToSliderItem(item)">{{item.title}}</div>
			
			<div v-if="!withUser" class="buy">
				<div v-if="tag && !withUser" class="tag">
					<img v-if="modifiedTag.image_url" :src="imgDomain + modifiedTag.image_url" class="img"/>
					<img v-else class="img empty"/>
					<span>{{modifiedTag.title ? modifiedTag.title : modifiedTag}}</span>
				</div>
				<div class="price title">
					<span>{{item.price}}  <span class="currency">{{currency}}</span></span>
				</div>
			</div>
			<div v-if="withUser" class="preview-container">
				<div class="preview" @click="goToSliderItem(item)" v-if="lazy">
					<img :data-lazy="imgDomain + item.image_url" class="img"/>
				</div>
				<div class="preview" @click="goToSliderItem(item)" v-else>
					<img :src="imgDomain + item.image_url" class="img"/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: 'ProductCard',
	props: ['item', 'sliderIsClickble', 'tag', 'currency', 'models', 'isAuth', 'lazy', 'withUser', 'isPack', 'currencyOptions', 'currencyValue'],
	data: function() {
		return {
			imgDomain: '',
		}
	},
	watch: {
	},
	computed: {
		modifiedTag: function() {
			if (typeof this.tag === 'object' && this.tag !== null) {
				return this.tag;
			} else if(typeof this.tag === 'number' && this.tag !== null) {
				let obj = this.models.find(item => item.id === this.tag);
				return obj;
			} else {
				return this.tag;
			}
		},
	},
	mounted() {
		this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
	},
	methods: {
		isNumber(variable) {
			return Number.isFinite(variable) && typeof variable === "number";
		},
		goToSliderItem(item) {
			let self = this;
			setTimeout(()=> {
				if (self.sliderIsClickble == true) {
					self.$emit('goToProduct', item);
				}
			}, 200)
		},
		openSignInModal() {
			this.$emit('openSignInModal');
		},
		openBuyModal(item, type) {
			this.$emit('openBuyModal', item, type)
		}
	},
}
</script>
